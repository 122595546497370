<template>
  <ResultDetail :user="user" :avatarText="avatarText" :searchText="searchText" :id="$route.params.id" />
</template>

<script>
import { ResultDetail } from 'exam-service-web-lib';
import { mapState, mapGetters } from 'vuex';

export default {
  name: 'ResultDetailPage',
  components: {
    ResultDetail,
  },
  computed: {
    ...mapState(['searchText']),
    ...mapState('Auth', ['user']),
    ...mapGetters('Auth', ['avatarText']),
  },
};
</script>

<style></style>
